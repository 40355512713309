<template>
  <RouterView />
</template>
<script setup lang="ts">
// NOTE: 화면 높이 계산
const setVH = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

setVH()
</script>

<style lang="scss">
@import 'scss/main.scss';

#app {
  width: 100%;
  height: 100%;

  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
  height: calc(var(--vh, 1vh) * 100) !important;
}

.assistant-page {
  width: 100vw;
  height: 100vh;

  max-width: 100vw;
  max-height: 100vh;

  .assistant-main {
    position: relative;
    width: 100%;
  }
}

.auth-page {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
